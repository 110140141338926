import React from 'react'
import "./Chats.css"
import Chat from "./Chat"

function Chats() {
    return (
        <div className='chats'>
            <Chat
                name='Mark'
                message='Hi!'
                timestamp="40 seconds ago"
                profilePic='http://4.bp.blogspot.com/-REHtzajoXQI/UuL1pFTbtOI/AAAAAAABA6s/f5TWz0lYBTg/s1600/Ensaio_WilliamNunes_phWongSim_01.jpg'>
            </Chat>

            <Chat
                name='Mark'
                message='Hi!'
                timestamp="40 seconds ago"
                profilePic='...'//'http://4.bp.blogspot.com/-REHtzajoXQI/UuL1pFTbtOI/AAAAAAABA6s/f5TWz0lYBTg/s1600/Ensaio_WilliamNunes_phWongSim_01.jpg'
            >
            </Chat>

        </div>
    )
}

export default Chats