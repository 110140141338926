import { React } from "react";
import PersonIcon from '@material-ui/icons/Person';
import ForumIcon from '@material-ui/icons/Forum';
import './Header.css'
import logo from './imgs/logo_flove.png';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";

import { Link, useHistory } from "react-router-dom";

function Header({ backButton }) {
    const history = useHistory()
    return (
        <div className="header">
            {backButton ? (
                <IconButton onClick={() => history.replace(backButton)}>
                    <ArrowBackIosIcon className="header_icon" fontSize="large"></ArrowBackIosIcon>
                </IconButton>
            ) : (

                <IconButton>
                    <PersonIcon className="header_icon" fontSize="large" />
                </IconButton>
            )}
            <Link to="/">
                <img alt='Logo' src={logo} className="header_logo" />
            </Link>

            <Link to="/chat">
                <ForumIcon className="header_icon" fontSize="large" />
            </Link>
        </div>
    )

}

export default Header