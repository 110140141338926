import React, { useState } from 'react'
import './ChatScreen.css'
import Avatar from '@material-ui/core/Avatar'

function ChatScreen() {
    const [input, setInput] = useState('')
    const [messages, setMessages] = useState([
        {
            name: 'Mark',
            image: '...',
            message: 'Oi'
        },
        {
            name: 'Mark',
            image: '...',
            message: "Tá afim de viajar comigo?"
        },
        {
            name: '',
            image: '...',
            message: 'Demorou!!!'
        }

    ])

    const handleSend = e => {
        e.preventDefault();
        setMessages([...messages, { message: input }])
        setInput('')
    }


    return (
        <div className='chatScreen'>
            <h2>ChatScreen</h2>
            <p className='chatScreen__timestamp'>YOU MATCHED WITH MARK ON 30/09/22</p>
            {messages.map(message =>

                message.name ? (
                    <div className='chatScreen__message'>
                        <Avatar className='chatScreen__image'
                            alt={message.name}
                            src={message.image} />
                        <p className='chatScreen__text'>{message.message}</p>
                    </div>
                ) : (
                    <div className='chatScreen__message'>
                        <p className='chatScreen__textUser'>{message.message}</p>
                    </div>
                )


            )}


            <form className='chatScreen__input'>
                <input className='chatScreen__inputField'
                    placeholder='Digite uma mensagem...'
                    type='text'
                    value={input}
                    onChange={e => setInput(e.target.value)}
                ></input>
                <button type='submit' onClick={handleSend} className='chatScreen_inputButton'>Enviar</button>
            </form>

        </div>
    )
}

export default ChatScreen