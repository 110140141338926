import { React, useState } from "react";
import TinderCard from 'react-tinder-card'
import './TinderCards.css'

function TinderCards() {
    const [people, setPeople] = useState([
        {
            name: 'xxx',
            url: 'https://www.desktopbackground.org/p/2015/12/30/1065740_forests-wallpapers_3840x2400_h.jpg',
            id: 1
        },

        {
            name: 'yyy',
            url: 'https://www.desktopbackground.org/p/2015/12/30/1065740_forests-wallpapers_3840x2400_h.jpg',
            id: 2
        },

    ])

    return (
        <div>
            <h1>Cards</h1>
            <div className="tinderCards__cardContainer">

                {people.map(person => (
                    <div className="swipe">
                        <TinderCard key={person.id} preventSwipe={['up', 'down']}>
                            {/* <div className="card"  > */}

                            <div style={{ backgroundImage: 'url(' + person.url + ')' }} className='card'>
                                <h3>{person.name}</h3>
                                {/* </div> */}
                            </div>
                        </TinderCard>
                    </div>

                ))
                }

            </div>

        </div >

    )

}

export default TinderCards;
